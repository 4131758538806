// 獲取台幣和USDC匯率
export const fetchTickerData = async () => {
  try {
    const response = await fetch(
      `https://yepcoin.org/api/v1/USDCTWDAPIListView/`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }

    const data = await response.json();

    console.log(data);

    return data;
  } catch (error) {
    console.error("Error fetching ticker data:", error);
    throw error;
  }
};

//取得USDC對YEP匯率
export const YEPUSDC = async () => {
  const apiEndpoint =
    "https://api.geckoterminal.com/api/v2/networks/arbitrum/tokens/0xb350bf8c544f82013c2991c3265e386700024131";

  try {
    const response = await fetch(apiEndpoint);
    const data = await response.json();
    const price = parseFloat(data["data"]["attributes"]["price_usd"]);
    console.log(price);
    return price;
  } catch (error) {
    console.error("Error fetching YEP to USDC rate:", error);
  }
};

//取得台幣對其他國家貨幣匯率
export const fetchCurrencyRates = async () => {
  const apiKey = "184d7c682996705f89772d0a";
  const url = `https://v6.exchangerate-api.com/v6/${apiKey}/latest/TWD`; // base_currency 設為 TWD
  const response = await fetch(url);
  const data = await response.json();

  if (data.result === "success") {
    const requiredCurrencies = ["USD", "EUR", "HKD", "CNY", "TWD", "VND"];
    const filteredRates = {};

    // 篩選出指定貨幣的匯率
    requiredCurrencies.forEach((currency) => {
      if (data.conversion_rates[currency]) {
        filteredRates[currency] = data.conversion_rates[currency];
      }
    });
    console.log(filteredRates);
    return filteredRates;
  } else {
    console.error("Error fetching data:", data);
    return null;
  }
};
