import React, { useState, useEffect } from "react";
import { fetchTickerData, YEPUSDC, fetchCurrencyRates } from "./api.js";
import QRCode from "qrcode";
import yiyuanbg from "../images/YUAN COIN後台-1.png";

const Trade = () => {
  const [amount, setAmount] = useState("");
  const [crypto, setCrypto] = useState("USDC");
  const [tickerData, setTickerData] = useState(null);
  const [usdcToYepRate, setUsdcToYepRate] = useState(null);
  const [currency, setCurrency] = useState("TWD");
  const [exchangeCurrency, setExchangeCurrency] = useState(null);
  const [receiveAddress, setReceiveAddress] = useState("");
  const [isAddressValid, setIsAddressValid] = useState(false);
  const USDC_ADDRESS = "0xaf88d065e77c8cC2239327C5EDb3A432268e5831";
  const YEP_ADDRESS = "0xB350BF8C544f82013c2991C3265e386700024131";

  const generateQRCodeSvg = async (text) => {
    try {
      const svg = await QRCode.toString(text, { type: "svg" });
      document.getElementById("qrcode-svg").innerHTML = svg;
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    const getTickerData = async () => {
      const data = await fetchTickerData();
      setTickerData(data);
    };
    getTickerData();
  }, []);

  useEffect(() => {
    const getUsdcToYepRate = async () => {
      const rate = await YEPUSDC();
      setUsdcToYepRate(rate);
    };
    getUsdcToYepRate();
  }, []);

  const calculateExchangeCurrency = async () => {
    const cachedRates = JSON.parse(localStorage.getItem("currencyRates"));
    const lastFetchTime = localStorage.getItem("currencyRatesTimestamp");
    const currentTime = Date.now();

    if (cachedRates && lastFetchTime && currentTime - lastFetchTime < 3600000) {
      const rates = cachedRates;
      processRates(rates);
    } else {
      const rates = await fetchCurrencyRates();
      localStorage.setItem("currencyRates", JSON.stringify(rates));
      localStorage.setItem("currencyRatesTimestamp", currentTime.toString());
      processRates(rates);
    }
  };

  const processRates = (rates) => {
    if (rates && rates[currency] !== undefined) {
      const rate = currency === "TWD" ? 1 : rates[currency];
      const exchangeAmount = amount / rate;
      setExchangeCurrency(exchangeAmount);
    } else {
      console.error(`找不到 ${currency} 的匯率`);
    }
  };

  useEffect(() => {
    if (amount) {
      calculateExchangeCurrency();
    }
  }, [amount, currency]);

  const handleTrade = async () => {
    let amountQR;
    if (crypto === "USDC") {
      const amountInUSDC = exchangeCurrency / tickerData.price;
      amountQR = Math.floor(amountInUSDC * Math.pow(10, 6)) / Math.pow(10, 6);
      console.log(exchangeCurrency);
      console.log(amountQR);
    } else if (crypto === "YEP") {
      const amountInUSDC = exchangeCurrency / tickerData.price;
      const amountInYEP = amountInUSDC / usdcToYepRate;
      amountQR = Math.floor(amountInYEP * Math.pow(10, 18)) / Math.pow(10, 18);
      console.log(exchangeCurrency);
      console.log(amountQR);
    }

    const tokenAddress = crypto === "USDC" ? USDC_ADDRESS : YEP_ADDRESS;
    const decimal = crypto === "USDC" ? `e6` : `e18`;

    const QR = `ethereum:${tokenAddress}@42161/transfer?address=${receiveAddress}&uint256=${amountQR}${decimal}`;
    generateQRCodeSvg(QR);
  };

  return (
    <div className="flex items-center justify-center min-h-screen relative">
      <div className="absolute inset-0 w-full h-full">
        <img
          src={yiyuanbg}
          alt="背景設計"
          className="w-full h-full object-cover opacity-90"
        />
      </div>
      <div className="fixed bottom-0 left-1/2 transform -translate-x-1/2 p-4 sm:p-6 rounded-lg w-full sm:max-w-md md:max-w-lg z-50">
        <div className="mb-2">
          <label className="block text-lg font-medium mb-1">
            Receive Address:
          </label>
          <input
            type="text"
            value={receiveAddress}
            onChange={(e) => {
              const value = e.target.value;
              setReceiveAddress(value);
              setIsAddressValid(/^0x[a-fA-F0-9]{40}$/.test(value));
            }}
            className="w-full p-1 border border-gray-300 rounded-md"
            placeholder="0x..."
            required
          />
        </div>

        <div className="mb-2">
          <label className="block text-lg font-medium mb-1">Currency:</label>
          <select
            value={currency}
            onChange={(e) => setCurrency(e.target.value)}
            className="w-full p-1 border border-gray-300 rounded-md"
          >
            <option value="TWD">TWD</option>
            <option value="USD">USD</option>
            <option value="EUR">EUR</option>
            <option value="HKD">HKD</option>
            <option value="CNY">CNY</option>
            <option value="VND">VND</option>
          </select>
        </div>

        <div className="mb-2">
          <label className="block text-lg font-medium mb-1">Amount:</label>
          <input
            type="text"
            value={amount}
            onChange={(e) => {
              const value = e.target.value.replace(/[^0-9]/g, "");
              const intValue = parseInt(value, 10);
              setAmount(intValue >= 1 ? intValue : "");
            }}
            className="w-full p-1 border border-gray-300 rounded-md"
            required
          />
        </div>

        <div className="mb-2">
          <label className="block text-lg font-medium mb-1">Crypto:</label>
          <select
            value={crypto}
            onChange={(e) => setCrypto(e.target.value)}
            className="w-full p-1 border border-gray-300 rounded-md"
          >
            <option value="USDC">USDC</option>
            <option value="YEP">YEP</option>
          </select>
        </div>

        <div
          className="w-36 h-36 bg-gray-200 rounded-lg mx-auto my-6"
          id="qrcode-svg"
        ></div>

        <div className="text-center">
          <button
            onClick={handleTrade}
            disabled={!isAddressValid || !amount}
            className={`px-4 py-1 mb-2 sm:px-6 ${
              isAddressValid && amount
                ? "bg-slate-600 hover:bg-slate-800"
                : "bg-gray-400 cursor-not-allowed"
            } text-white rounded-md focus:outline-none duration-200`}
          >
            Trade
          </button>
        </div>
      </div>
    </div>
  );
};

export default Trade;
