import "./App.css";
import Trade from "./components/Trade";

function App() {
  return (
    <div>
      <Trade />
    </div>
  );
}

export default App;
